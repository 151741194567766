import React, { Component, createContext } from 'react';
import { Cookies } from 'react-cookie';
import { makeVar } from '@apollo/client';
import http from 'axios';
import { camelCase } from 'camel-case';
import deepMerge from 'deepmerge';
import { decodeHtmlCharRef } from '../utils/html';
import { getNestedQueryParameter } from './utils/url';
import { Country, I18nKey, Language, getLanguage, i18nInit, languagePropName, languageCookiePropName } from '../i18n';
import { ApiClient } from '../api/client';
import { getLog } from '../Log';
import { showMarketingEmailOptInForCountry } from '../marketing';
import { RecaptchaSiteKey, RecaptchaEnterpriseKeyId } from '../recaptcha';
import {
  captchaBypassVar,
  embeddedVar,
  errorsVar,
  formInputErrorsVar,
  loadCookieState,
  loadDebugState,
  loadUrlState,
  referrerVar,
  unexpectedErrorVar,
} from './local-state';

export const formatFormInputErrors = (err = {}) =>
  Object.keys(err)
    .filter((key) => !err[key].valid)
    .reduce((errors, key) => {
      if (Array.isArray(err[key].errors) && err[key].errors.length) {
        const error = err[key].errors[0];
        const text = error.text || error.message;
        if (text) {
          errors[key] = text;
        }
      }
      return errors;
    }, {});

export const LoginResult = {
  Redirect: 'Redirect',
  RequiresPassword: 'RequiresPassword',
  SamlUser: 'SamlUser',
};

export const ApplicationStateContext = createContext({});

/**
 * Action handlers
 *
 * These methods are action handlers. They will be called when the
 * state needs to be changed, usually due to a user interaction, timer
 * completion, or some other event.
 */
export const actionHandlers = {
  async initialize() {
    if (!initializingVar()) {
      return;
    }

    const queryHl = getNestedQueryParameter(window.location, languagePropName);
    const queryLanguage = getNestedQueryParameter(window.location, 'language');
    const language =
      getLanguage(
        localStorage.getItem('language') || queryHl || queryLanguage || this.cookies[languageCookiePropName],
      ) || this.cookies[languagePropName];

    const { i18n } = await i18nInit({ language: language.code });

    this.i18n = i18n;

    languageVar({
      code: language.code,
      name: language.name,
    });

    if (language.code !== this.cookies[languageCookiePropName]) {
      this.actions.changeLanguage(language.code, language.name);
    }

    if (queryHl) {
      const newUrl = new URL(window.location);
      newUrl.searchParams.delete('hl');
      window.history.replaceState({}, '', newUrl);
    }

    if (queryLanguage) {
      const newUrl = new URL(window.location);
      newUrl.searchParams.delete('language');
      window.history.replaceState({}, '', newUrl);
    }

    initializingVar(false);
  },

  changeLanguage(code = Language.English.code, name = Language.English.name) {
    this.log.info('action: changeLanguage', { action: 'changeLanguage', code });

    this.api.changeLanguage(code).catch((err) => {
      this.log.error('Failed to change language. Language selection may not be persisted.', { err });
    });

    this.i18n.changeLanguage(code);

    languageVar({
      code,
      name,
    });
  },

  clearErrors() {
    errorsVar(null);
    formInputErrorsVar(null);
  },

  async createUser(
    email,
    password,
    {
      marketingEmailOptIn = false,
      implicitAgreeTOS = false,
      nextUrl = null,
      isSsoFlow = false,
      identityProviderName = null,
      verificationCode = null,
      recaptchaValue,
      fullName,
      displayName,
      tosPayload,
      flow = null,
    } = {},
  ) {
    const site = referrerVar()?.site;

    this.log.info('action: createUser', {
      action: 'createUser',
      isSsoFlow,
      identityProviderName,
      email,
      marketingEmailOptIn,
      implicitAgreeTOS,
      nextUrl,
      verificationCode,
      recaptchaValue,
      fullName,
      displayName,
      site,
      flow,
    });

    userVar({
      ...userVar(),
      creating: true,
    });

    const payload = {
      email_register: email,
      ...(isSsoFlow ? {} : { password }),
      ...(fullName ? { full_name: fullName } : {}),
      ...(displayName ? { username: displayName } : {}),
      next: nextUrl,
      sso_flow: isSsoFlow,
      special_offers: marketingEmailOptIn,
      implicit_agree_tos: implicitAgreeTOS,
      ...(implicitAgreeTOS ? { tos_v: termsOfServiceVar().version } : {}),
      ...(tosPayload || {}),
      verification_code: verificationCode,
      'g-recaptcha-response': recaptchaValue,
      site,
      flow,
    };

    try {
      const result = await this.api.createUser(payload);
      await this.actions.createUserSuccess({ email, isSsoFlow, identityProviderName, ...result });
    } catch (err) {
      this.actions.createUserFailure(err);
    }
  },

  async createUserSuccess({ id, external_realm_user_id, email, next_url = '/', isSsoFlow, identityProviderName } = {}) {
    this.log.info('action: createUserSuccess', { action: 'createUserSuccess', email, next_url });

    const userId = id || external_realm_user_id;
    const creationDate = new Date();

    const analyticsUser = {
      id: userId && String(userId),
      user_id: userId && String(userId),
      accountLanguage: '',
      collections: {},
      creationDate,
      isActive: 'true',
      isImpersonated: 'false',
      status: 'guest',
      totalNumSubscriptionsActive: '0',
      totalNumSubscriptionsAllTime: '0',
    };

    const event = {
      id: analyticsUser.id,
      user_id: analyticsUser.user_id,
      email,
      isActive: 'true',
      status: 'customer',
      creationDate,
      user: analyticsUser,
    };
    if (isSsoFlow) {
      event.provider = identityProviderName;
    }
    await this.analytics.signedUp(event);

    // Redirect
    window.location.href = next_url;
  },

  createUserFailure(err) {
    this.log.info('action: createUserFailure', { action: 'createUserFailure', err });

    let formInputErrors = null;
    if (err.fields) {
      formInputErrors = formatFormInputErrors(err.fields);

      const remapFields = [
        { current: 'full_name', new: 'fullName' },
        { current: 'username', new: 'displayName' },
      ];

      for (const remapField of remapFields) {
        if (formInputErrors[remapField.current]) {
          formInputErrors[remapField.new] = formInputErrors[remapField.current];
        }
      }
    }

    let errors = null;
    if (err.error) {
      errors = [err.error];
    }

    if (err.showRecaptcha !== undefined) {
      showRecaptchaVar(err.showRecaptcha);
    }

    userVar({
      ...userVar(),
      creating: false,
    });

    errorsVar(errors);
    formInputErrorsVar(formInputErrors);
  },

  async fetchUserForgetStatus() {
    this.log.info('action: fetchUserForgetStatus', { action: 'fetchUserForgetStatus' });

    if (userVar().fetchingUserForgetStatus) {
      return;
    }

    userVar({
      ...userVar(),
      fetchingUserForgetStatus: true,
      canNotBeForgottenReasons: [],
    });

    let payload;

    try {
      payload = await this.api.fetchUserForgetStatus();
    } catch (err) {
      return this.actions.fetchUserForgetStatusFailure();
    }

    this.actions.fetchUserForgetStatusSuccess(payload);
  },

  fetchUserForgetStatusSuccess(userForgetStatus) {
    this.log.info('action: fetchUserForgetStatusSuccess', {
      action: 'fetchUserForgetStatusSuccess',
      userForgetStatus,
    });

    userVar({
      ...userVar(),
      fetchingUserForgetStatus: false,
      canNotBeForgottenReasons: userForgetStatus.eligibility.checks
        .filter((check) => !check.isEligible)
        .map((check) => check.key),
    });
  },

  fetchUserForgetStatusFailure() {
    this.log.info('action: fetchUserForgetStatusFailure', { action: 'fetchUserForgetStatusFailure' });

    // Assume that the user can be forgotten. If they cannot, they will get an
    // error message when they actually make the forget user request.
    userVar({
      ...userVar(),
      fetchingUserForgetStatus: false,
      canNotBeForgottenReasons: ['InternalError'],
    });
  },

  /**
   * Set initial state at the beginning of the login process.
   * @param {boolean} loggingIn
   * @param {LoginResult.Redirect|LoginResult.RequiresPassword|LoginResult.SamlUser} loggingResult
   * @param {Object} samlLogin
   * @param {String=} samlLogin.loginUrl - saml login url
   * @param {String=} samlLogin.providerName - saml provider name
   */
  setLoggingIn(loggingIn, loginResult = null, samlLogin = {}) {
    userVar({
      ...userVar(),
      loggingIn,
      loginResult,
      samlLogin,
    });
  },

  async loginSso(username, { nextUrl = null, recaptchaValue, openSsoLoginInNewTab } = {}) {
    this.log.info('action: login-sso', { action: 'login', username, recaptchaValue });

    this.actions.setLoggingIn(true);

    const payload = {
      next: nextUrl,
      emailOrUsername: username,
      captcha_bypass: captchaBypassVar(),
      'g-recaptcha-response': recaptchaValue,
    };

    let data;
    try {
      data = await this.api.loginSso(payload);
    } catch (error) {
      error.error = { ...error };
      return this.actions.loginFailure({ err: error });
    }

    this.actions.loginSsoSuccess({ ...data, openSsoLoginInNewTab });
  },

  loginSsoSuccess({ loginUrl, openSsoLoginInNewTab }) {
    this.log.info('action: loginSsoSuccess', { action: 'loginSsoSuccess' });
    this.actions.setLoggingIn(false, LoginResult.Redirect);

    if (openSsoLoginInNewTab) {
      const ssoLoginWindow = window.open(loginUrl, 'socialsignon');

      if (!ssoLoginWindow) {
        console.warn('Failed to open SSO login window. Please enable popups for this site.');
      } else {
        ssoLoginWindow.focus();
      }
    } else {
      window.location.assign(loginUrl);
    }
  },

  async login(username, password, { nextUrl = null, recaptchaValue } = {}) {
    this.log.info('action: login', { action: 'login', username, recaptchaValue });

    this.actions.setLoggingIn(true);

    const payload = {
      username,
      password,
      next: nextUrl,
      'g-recaptcha-response': recaptchaValue,
      captcha_bypass: captchaBypassVar(),
    };

    try {
      const response = await this.api.login(payload);
      await this.actions.decideLoginSuccess(response);
    } catch (err) {
      this.actions.loginFailure({ err, next_url: err.next_url });
    }
  },

  decideOTPSuccess({ ok, errors, next_url }) {
    if (ok) {
      this.actions.otpSuccess({ next_url });
    } else {
      this.actions.otpFailure({ err: errors, next_url });
    }

    return ok;
  },

  otpSuccess({ next_url = '/' } = {}) {
    this.log.info('action: otpSuccess', { action: 'otpSuccess' });

    window.location.href = next_url;
  },

  otpFailure({ err, next_url }) {
    this.log.info('action: otpFailure', { action: 'otpFailure', err });
    if (next_url) {
      window.location.href = next_url;
      return;
    }

    let errors = null;
    if (err.error) {
      errors = [err.error];
    }

    if (Array.isArray(err)) {
      errors = [
        ...(errors || []),
        ...err.filter((error) => error.text).map(({ text, code }) => ({ text: decodeHtmlCharRef(text), code })),
      ];
    }

    errorsVar(errors);
  },

  async decideLoginSuccess({ ok, verification_required, errors, next_url, user }) {
    if (verification_required) {
      this.actions.loginVerificationRequired({ next_url });
    } else if (ok) {
      await this.actions.loginSuccess({ user, next_url });
    } else {
      this.actions.loginFailure({ err: errors, next_url });
    }

    return ok;
  },

  async loginSuccess({ user, next_url = '/' } = {}) {
    this.log.info('action: loginSuccess', { action: 'loginSuccess' });

    if (user) {
      // Note: the user provides an email for signing in. The value sent to the tracking method,
      // however, is the username returned from the API call, which is not the same.
      const userId = user.id && String(user.id);
      const analyticsUser = {
        id: userId,
        user_id: userId,
        isActive: 'true',
        isImpersonated: 'false',
        status: 'customer',
      };

      const event = {
        id: analyticsUser.id,
        user_id: analyticsUser.user_id,
        isActive: 'true',
        status: 'customer',
        signInType: 'credentials',
        username: user.username,
        user: analyticsUser,
      };

      await this.analytics.signedIn(event);
    }

    window.location.href = next_url;
  },

  loginVerificationRequired({ next_url = '/' } = {}) {
    this.log.info('action: loginVerificationRequired', { action: 'loginVerificationRequired' });

    // TODO: google analytics
    window.location.href = next_url;
  },

  loginFailure({ err, next_url }) {
    this.log.info('action: loginFailure', { action: 'loginFailure', err });
    if (next_url) {
      window.location.href = next_url;
      return;
    }

    let errors = null;
    if (err.error) {
      errors = [err.error];
    }

    if (Array.isArray(err)) {
      errors = [
        ...(errors || []),
        ...err.filter((error) => error.text).map(({ text, code }) => ({ text: decodeHtmlCharRef(text), code })),
      ];
    }

    if (err.showRecaptcha !== undefined) {
      showRecaptchaVar(err.showRecaptcha);
    }

    errorsVar(errors);

    userVar({
      ...userVar(),
      loggingIn: false,
      loggedIn: false,
    });
  },

  requestCredentialsResetLink(email) {
    this.log.info('action: requestCredentialsResetLink', { action: 'requestCredentialsResetLink', email });

    return this.api.requestCredentialsResetLink(email);
  },

  requestCredentialsChange(currentPassword, newPassword, newConfirmPassword, nextUrl) {
    this.log.info('action: requestCredentialsChange', { action: 'requestCredentialsChange' });

    return this.api
      .requestCredentialsChange(currentPassword, newPassword, newConfirmPassword, nextUrl)
      .then((response) => this.actions.requestCredentialsChangeSuccess(response));
  },

  requestCredentialsChangeSuccess({ next_url: nextUrl = '/' } = {}) {
    this.log.info('action: requestCredentialsChangeSuccess', { action: 'requestCredentialsChangeSuccess' });

    window.location.href = nextUrl;
  },

  requestEmailChange({ newEmail, confirmEmail, password, nextUrl }) {
    this.log.info('action: requestEmailChangeSuccess', { action: 'requestEmailChangeSuccess' });

    return this.api
      .requestEmailChange({ newEmail, confirmEmail, password, nextUrl })
      .then((response) => this.actions.redirectAfterSuccess(response), 'requestEmailChangeSuccess');
  },

  redirectAfterSuccess({ next_url = '/' } = {}, action) {
    this.log.info(`action: ${action}`, { action: action });
    if (next_url) {
      window.location.href = next_url;
    }
  },

  showUnexpectedError() {
    unexpectedErrorVar({
      show: true,
      message: this.i18n.t(I18nKey.WentWrong),
    });
  },

  hideUnexpectedError() {
    unexpectedErrorVar({
      show: false,
      message: '',
    });
  },
};

// The ApplicationStateProvider component keeps the application state and contains action handler
// methods for changing state. These methods are made available to other components
// in the tree. The StateProvider should be rendered near the top of the tree, above
// all stateful components.
export class ApplicationStateProvider extends Component {
  constructor(props) {
    super(props);

    this.log = getLog('StateProvider');
    this.analytics = props.analytics;
    this.cookies = new Cookies(window.document.cookie).cookies;
    this.api = new ApiClient({
      notifyUnexpectedError: () => this.actions.showUnexpectedError(),
      originalAnalyticsWrapper: props.originalAnalyticsWrapper,
    });

    // Bind the action handlers to this instance.
    this.actions = {};
    Object.entries(actionHandlers).forEach(([key, value]) => (this.actions[key] = value.bind(this)));
  }

  render() {
    return (
      <ApplicationStateContext.Provider
        value={{
          // Expose the action handler methods so that they are accessible to other
          // components further down in the tree.
          ...this.actions,
          api: this.api,
        }}>
        {this.props.children}
      </ApplicationStateContext.Provider>
    );
  }
}

export const ApplicationStateConsumer = ({ children }) => (
  <ApplicationStateContext.Consumer>{children}</ApplicationStateContext.Consumer>
);

// Apollo client reactive variables

/**
 * Fetches data from the server that is needed to bootstrap the application.
 */
export const fetchPageLoadState = async ({ anonymousUserId = '' }) => {
  try {
    // TODO: fix blindly passing search params. Params should be explicit and only include data that is needed
    // for Launch Darkly feature flag configuration.
    const uriPath = `/web/api/system/bootstrap-data?${new URLSearchParams(
      window.location.search,
    ).toString()}&user=${anonymousUserId}`;

    const {
      data: { data },
    } = await http.get(uriPath, {
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    if (typeof data.country === 'string') {
      data.countryCode = data.country;
    }

    return data;
  } catch (error) {
    // don't try to check for process var existence
    // webpack 5 replaces process.env.NODE_ENV to a concrete string upon compile
    if (process.env.NODE_ENV === 'development') {
      return {};
    }

    throw error;
  }
};

export const syncState = async ({ pageLoadState, anonymousUserId } = {}) => {
  if (!pageLoadState) {
    pageLoadState = await fetchPageLoadState({ anonymousUserId });
  }

  const cookieState = loadCookieState();
  const urlState = loadUrlState();
  const debugState = loadDebugState();
  const state = deepMerge.all([pageLoadState, cookieState, urlState, debugState]);

  const countryCode = state.countryCode || Country.UnitedStates.code;
  const language = getLanguage();

  const analyticsSite =
    state.currentSite?.analyticsName || state.currentSite?.name || state.referrer.site || 'accounts';

  analyticsVar({
    environment: state.webAnalyticsEnvironment,
    hightouchKey: state.webAnalyticsHightouchKey,
    site: camelCase(analyticsSite),
  });

  showUiDeveloperWarningsVar(state.showUiDeveloperWarnings);
  requestEnterpriseDemoUrlVar(pageLoadState.requestEnterpriseDemoUrl);
  countryCodeVar(countryCode);
  csrfTokenVar(state.csrfToken || csrfTokenVar());
  embeddedVar(state.embedded || embeddedVar());
  featureFlagsVar(state.featureFlags || {});
  languageVar(language);
  marketingEmailOptInVar({ show: showMarketingEmailOptInForCountry(countryCode) });
  nextUrlVar(state.nextUrl || '/');
  allowEnterpriseAccountCreationVar(state.allowEnterpriseAccountCreation);
  presetVar(state.preset);
  fullWidthVar(state.fullWidth || fullWidthVar());
  recaptchaSiteKeyVar(state.recaptchaSiteKey || RecaptchaSiteKey.Localhost);
  recaptchaKeyIdVar(state.recaptchaKeyId || RecaptchaEnterpriseKeyId.Localhost);
  showRecaptchaVar(state.showRecaptcha);
  realmVar(state.realm);
  ssoVar({
    ...ssoVar(),
    ...state.sso,
  });
  isRecentlyAuthenticatedFlowVar(state.isRecentlyAuthenticatedFlow || false);

  termsOfServiceVar({ version: null });
  userVar({ ...userVar(), ...state.user });
  flowVar(state.flow || flowVar());
  signupPathVar(state.signupPath || signupPathVar());
};

/**
 * Web analytics settings.
 */
export const analyticsVar = makeVar({
  environment: '',
  site: '',
});

export const requestEnterpriseDemoUrlVar = makeVar('');

export const showUiDeveloperWarningsVar = makeVar(false);

/**
 * The ISO 3166‑1 alpha-2 country code for the current user.
 */
export const countryCodeVar = makeVar();

/**
 * Determines if the create account button should be shown
 */
export const allowEnterpriseAccountCreationVar = makeVar();

/**
 * The Cross-Site Request Forgery token for the current page load.
 */
export const csrfTokenVar = makeVar('');

/**
 * Represents the current realm.
 */
export const realmVar = makeVar('');

/**
 * Represents Launch Darkly feature flag state.
 */
export const featureFlagsVar = makeVar({});

/**
 * The current UI flow. This variable is newer than other flow-specific parameters, which should
 * eventually be refactored to use this one, if possible.
 */
export const flowVar = makeVar(null);

/**
 * The fullWidth. This value is determined from the query.
 */
export const fullWidthVar = makeVar(false);

/**
 * Represents the application state during bootstrap. When `false`, the
 * application is considered ready for user interaction.
 */
export const initializingVar = makeVar(true);

/**
 * The language preference for the current user.
 */
export const languageVar = makeVar({
  /**
   * The ISO 639-1 language code for the current language selection.
   */
  code: Language.English.code,

  /**
   * The localized display name for the current language selection.
   */
  name: Language.English.name,
});

/**
 * Opt-in settings for marketing emails. These values are determined by the user's
 * geographic location.
 */
export const marketingEmailOptInVar = makeVar({
  /**
   * When `true`, the user must be shown an option to opt into or out of receiving marketing
   * emails.
   */
  show: false,
});

/**
 * The URL to which the user is redirected after completing an operation, such as logging
 * in or signing up. This value is determined on the server and must be validated to prevent
 * open redirect vulnerabilities.
 */
export const nextUrlVar = makeVar(undefined);

/**
 * The preset. This value is determined from the query.
 */
export const presetVar = makeVar(undefined);

/**
 * The recaptcha site key for the respective deployment environment.
 */
export const recaptchaSiteKeyVar = makeVar('');

/**
 * The reCAPTCHA Enterprise key ID for the respective deployment environment.
 */
export const recaptchaKeyIdVar = makeVar('');

/**
 * If `true`, the user is required to prove that they are not a robot when logging in.
 */
export const showRecaptchaVar = makeVar(false);

/**
 * The path for the signup page.
 */
export const signupPathVar = makeVar('/users/new');

/**
 * State related to Single Sign On workflows.
 */
export const ssoVar = makeVar({
  /**
   * When `true`, the user has been redirected to the application in a SSO flow.
   */
  isSso: false,

  /**
   * Display-friendly description of the referring identity provider.
   */
  identityProviderDescription: '',

  /**
   * User profile data supplied by the external identity provider.
   */
  profile: {
    email: '',
  },
});

/**
 * Represents Recently Authenticated Flow i.e. first login flow state.
 */
export const isRecentlyAuthenticatedFlowVar = makeVar(false);

/**
 * Terms of service state.
 */
export const termsOfServiceVar = makeVar({
  /**
   * The version of the terms of service at the time the page was loaded.
   */
  version: null,
});

/**
 * Represents the current user. When `loggedIn` is `false`, then the user represents
 * an unauthenticated (anonymous) user.
 */
export const userVar = makeVar({
  creating: false,
  loggingIn: false,
  loggedIn: false,
  loginResult: null,
  fetchingCanBeForgotten: false,
  canNotBeForgottenReasons: [],
  verificationRequired: false,
  verificationNamespace: '',
  verificationStrategy: '',
  verificationIdentifier: '',
  verificationNextUrl: '',
  username: null,
  email: '',
});
