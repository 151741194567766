import { Cookies } from 'react-cookie';
import { makeVar } from '@apollo/client';
import deepMerge from 'deepmerge';
import { languagePropName, languageCookiePropName } from '../i18n';
import { getNestedQueryParameter, sanitizeRedirectUrl } from './utils/url';

/**
 * Local application state comprises state that is maintained or defined in the browser,
 * including the URL, local and session storage, and cookies. State values that come from
 * local stores generally have higher precedence than state originating from a remote source.
 */

/**
 * @apollo/client reactive variables. These are intended to manage state that is
 * managed locally in the browser and can be used with having to use a GraphQL
 * query. They are also used for local-only fields in the GraphQL schema.
 * @see https://www.apollographql.com/docs/react/local-state/reactive-variables/
 */

/**
 * For testing, there are certain cases where Google reCAPTCHA can be bypassed
 * using a query string parameter, `bypass_captcha`.
 */
export const captchaBypassVar = makeVar(false);

/**
 * For embedded state e2e testing
 */
export const forceLoginOtpVar = makeVar(false);

/**
 * When `true`, render the application in debug mode.
 */
export const debugVar = makeVar(false);

/**
 * When `true`, the application is embedded, e.g. using an iframe.
 */
export const embeddedVar = makeVar(false);

/**
 * Represents top-level errors.
 */
export const errorsVar = makeVar(null);

/**
 * Represents top-level errors related to form input.
 */
export const formInputErrorsVar = makeVar(null);

/**
 * When `true`, the user is in an invitation flow.
 */
export const invitationFlowVar = makeVar(false);

/**
 * The OAuth client ID of the referrer.
 */
export const oauthClientIdVar = makeVar('');

/**
 * When defined, prefill application input elements with the specified email address.
 */
export const prefillEmailVar = makeVar('');

/**
 * Represents the referring web property, which is generally resolved from the value
 * of the `site` query string parameter (and may be nested in other parameters). At
 * present, the value of `referrer.key` determines the top-level component
 * that is defined in a www-* module. This behavior is expected to change with IDN-2378.
 */
export const referrerVar = makeVar({
  /**
   * The identifier of the referring web property.
   */
  key: undefined,

  /**
   * The value of the `site` query string parameter.
   */
  site: '',

  /**
   * The display name for the referrer. This value appears, e.g., in the `title` element.
   */
  displayName: '',
});

/**
 * Represents a top-level unexpected error, such as a 5xx response from an XHR request.
 */
export const unexpectedErrorVar = makeVar({
  /**
   * If `true`, display the error, e.g. in a toast.
   */
  show: false,

  /**
   * The error message to display.
   */
  message: '',
});

/**
 * Syncs the local reactive variable state. This function should be invoked during
 * application bootstrap to ensure that the reactive variables are initialized with
 * the current values.
 */
export const syncLocalState = () => {
  const state = deepMerge.all([loadCookieState(), loadUrlState(), loadDebugState()]);

  captchaBypassVar(state.captchaBypass);
  forceLoginOtpVar(state.forceLoginOtp);
  debugVar(coerceBooleanString(state.debug));
  invitationFlowVar(state.invitationFlow);
  oauthClientIdVar(state.oauthClientId);
  prefillEmailVar(state.prefillEmail);
  referrerVar({ ...state.referrer, site: state.referrer?.site || state.site });
};

/**
 * Debug state
 */

/**
 * Loads persistent state from `localStorage` for debugging.
 */
export const loadDebugState = () => {
  let debugState = {};

  try {
    const debugStateStr = localStorage.getItem('debug-state');

    debugState = JSON.parse(debugStateStr);

    if (!debugState || Array.isArray(debugState)) {
      debugState = {};
    }
  } catch (error) {
    console.error('Failed to load debug state: invalid JSON.');
  }

  return debugState;
};

/**
 * URL state.
 */

/**
 * Returns a state object constructed from the specified URL search parameter.
 *
 * @param {object} options
 * @param {string} [options.searchParamKey] The name of the URL search parameter containing the state value.
 * @param {string} [options.stateKey] If provided, the key to which the value is assigned on the return object;
 * otherwise, the value will be assigned to using `searchParamKey`.
 * @returns {object} State object.
 */
export const loadUrlSearchParamState = ({ searchParamKey, searchParamType, stateKey } = {}) => {
  const state = {};

  const searchParamValue = getNestedQueryParameter(window.location, searchParamKey, {
    recurse: true,
    type: searchParamType,
  });

  if (searchParamValue) {
    state[stateKey || searchParamKey] = searchParamValue;
  }

  return state;
};

/**
 * Loads state from the URL.
 */
export const loadUrlState = () => {
  let state = {};

  const searchParams = new URLSearchParams(window?.location?.search);

  // Query string parameters that have been added over the years. We hope to eventually
  // replace most of these with a standard and well-documented configuration format, at
  // which time the old keys will be deprecated.
  [
    { searchParamKey: 'captcha_bypass', stateKey: 'captchaBypass' },
    { searchParamKey: 'client_id', stateKey: 'oauthClientId' },
    { searchParamKey: 'embedded', searchParamType: 'boolean' },
    { searchParamKey: 'preset', searchParamType: 'string' },
    { searchParamKey: 'fullWidth', searchParamType: 'boolean' },
    { searchParamKey: 'force_show_recaptcha', stateKey: 'forceShowRecaptcha' },
    { searchParamKey: 'force_login_otp', stateKey: 'forceLoginOtp', searchParamType: 'boolean' },
    { searchParamKey: 'prefill_email', stateKey: 'prefillEmail' },
    { searchParamKey: 'site' },
  ].forEach(({ searchParamKey, searchParamType, stateKey }) => {
    state = deepMerge.all([state, loadUrlSearchParamState({ searchParamKey, searchParamType, stateKey })]);
  });

  // `flow`
  state.flow = searchParams.get('flow');

  // `language from hl param`
  const hlCodeValue = getNestedQueryParameter(window.location, languagePropName);

  if (hlCodeValue) {
    state.language = {
      code: hlCodeValue,
    };
  }

  // `language from language param`
  const languageCodeValue = getNestedQueryParameter(window.location, 'language');

  if (languageCodeValue) {
    state.language = {
      code: languageCodeValue,
    };
  }

  // `referrer`
  state.referrer = resolveReferrer();

  // `sso`
  const isSsoValue = searchParams.get('sso_flow');

  state.sso = {};

  if (typeof isSsoValue === 'string') {
    state.sso.isSsoRequested = isSsoValue === 'true';
  }

  // `invitationFlow`
  // Falls back to determining whether the user is in an invitation flow based on the
  // OAuth client ID (for any environment). The invitations service itself should be
  // updated to provide the `invitationFlow` parameter.
  const invitationFlow = getNestedQueryParameter(window.location, 'invitationFlow', { type: 'boolean', recurse: true });
  const oauthClientId = getNestedQueryParameter(window.location, 'client_id', { recurse: true });
  const invitationFlowOauthClientIds = ['invitation-service-dev', 'invitation-service-qa', '042a9005cb0357cad55b'];

  state.invitationFlow = invitationFlow || invitationFlowOauthClientIds.includes(oauthClientId);

  // Load applicationState parameters for debugging. These are in the format
  // applicationState.foo=bar and take precedence over any of the above parameters.
  const applicationStateParams = new URLSearchParams(
    Array.from(searchParams.entries()).filter(([key]) => key.startsWith('applicationState')),
  );

  for (let [paramKey, paramValue] of applicationStateParams) {
    const [, stateKey] = paramKey.split('.');
    if (stateKey === 'nextUrl') {
      state[stateKey] = sanitizeRedirectUrl(paramValue);
    } else {
      state[stateKey] = paramValue;
    }
  }

  return state;
};

/**
 * Cookie state
 */

/**
 * Loads state from cookies.
 */
export const loadCookieState = () => {
  const language = {};

  const cookies = new Cookies(window.document.cookie).cookies;

  // Language
  const languageCodeCookieValue = cookies[languageCookiePropName] || cookies[languagePropName];

  if (languageCodeCookieValue) {
    language.code = languageCodeCookieValue;
  }

  const state = {
    language,
  };

  return state;
};

/**
 * Helper functions.
 */

const coerceBooleanString = (value) => (typeof value === 'string' ? value === 'true' : value);

export const referrerMetadataBySite = {
  default: { site: undefined, displayName: 'Shutterstock' },
  image: { site: 'image', displayName: 'Image' },
  premier: { site: 'premier', displayName: 'Premier' },
};

export const resolveReferrer = () => {
  let site = getNestedQueryParameter(window.location, 'site', { recurse: true });

  if (!site || referrerMetadataBySite[site] === undefined) {
    site = 'default';
  }

  const referrerMetadata = referrerMetadataBySite[site];

  return { key: site, ...referrerMetadata };
};
