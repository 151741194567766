import { lazy } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { interactionUidVar } from './state';

const NotFoundPage = lazy(() => import('../../error-pages/NotFoundPage'));

const Identifier = lazy(() =>
  import('./Identifier/Identifier').then(({ Identifier }) => ({
    default: Identifier,
  })),
);

const PasswordChallenge = lazy(() =>
  import('./PasswordChallenge/PasswordChallenge').then(({ PasswordChallenge }) => ({
    default: PasswordChallenge,
  })),
);

const CreateAccount = lazy(() =>
  import('./CreateAccount/CreateAccount').then(({ CreateAccount }) => ({
    default: CreateAccount,
  })),
);

export function AuthInteractionRouter() {
  const [searchParams] = useSearchParams();

  const interactionUid = searchParams.get('uid');

  if (interactionUid) {
    interactionUidVar(interactionUid);
  }

  return (
    <Routes>
      <Route index element={<Navigate to="identifier" replace />} />
      <Route path="signin/identifier" element={<Identifier />} />
      <Route path="signin/challenge/password" element={<PasswordChallenge />} />
      <Route path="signup/createaccount" element={<CreateAccount />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
